<script setup lang="ts">
// Packages
import { storeToRefs } from 'pinia';
import { useAlertsStore } from '@/store/alerts';
import { useUserStore } from '@/store/user';

const colorMode = useColorMode();
const { t } = useI18n();
const isOpen = ref(false);
const store = useAlertsStore();
const userStore = useUserStore();
const { quantity } = storeToRefs(store);
const { isAdmin } = storeToRefs(userStore);

const links = computed(() => {
  const defaultMenuItems = [
    {
      label: t('HOME'),
      icon: 'i-heroicons-home',
      to: '/',
    },
    {
      label: t('USERS'),
      icon: 'i-heroicons-user-group',
      to: '/users',
    },
    {
      label: t('IMMOBILES'),
      icon: 'i-heroicons-home-modern',
      to: '/immobiles',
    },
    {
      label: t('NEARBY_ALERTS'),
      icon: 'i-heroicons-clock',
      to: '/nearby_alert',
      badge: quantity.value,

    },
    {
      label: t('RENTALS'),
      icon: 'i-heroicons-building-office-2',
      to: '/rentals',

    },
    {
      label: t('GARANTEES'),
      icon: 'i-heroicons-check-badge',
      to: '/garantees',

    },
    {
      label: t('LEAD_SOURCES'),
      icon: 'i-heroicons-megaphone',
      to: '/lead_sources',

    },
    {
      label: t('CUSTOMERS'),
      icon: 'i-heroicons-users',
      to: '/customers',
    },
    {
      label: t('REPORTS'),
      icon: 'i-heroicons-chart-bar-square',
      to: '/reports',
    }
  ];

  if (!isAdmin.value) {
    return defaultMenuItems.filter((mI) => ['/immobiles', '/nearby_alert', '/', '/customers'].includes(mI.to));
  }

  return defaultMenuItems;

});

const linksMobile = computed(() => links.value.map((l) => ({ ...l, click: toggleMnMb })));

function toggleMnMb() {
  isOpen.value = !isOpen.value;
}

</script>

<template>
  <div>
    <div>
      <nuxt-link to="/">
        <img :src="`/assets/${colorMode.value === 'light' ? 'logo-dark.png' : 'logo.webp'}`" alt="Vivali logo" class="logo my-2 block">
      </nuxt-link>
      <UVerticalNavigation :links="links" class="mt-9"/>
    </div>

    <ClientOnly>
      <Teleport to="#mobile_menu">
        <UButton
          :padded="false"
          color="gray"
          variant="link"
          icon="i-heroicons-bars-3-solid"
          @click="toggleMnMb"
        />
      </Teleport>
    </ClientOnly>

    <ClientOnly>
      <Teleport to="body">
        <USlideover v-model="isOpen" side="left">
          <div class="p-4 flex-1">
            <div class="flex justify-between justify-items-center">
              <nuxt-link to="/" @click="toggleMnMb">
                <img :src="`/assets/${colorMode.value === 'light' ? 'logo-dark.png' : 'logo.webp'}`" alt="Vivali logo" class="logo my-2 block">
              </nuxt-link>
              
              <UButton color="gray" variant="ghost" icon="i-heroicons-x-mark-20-solid" class="self-center" @click="isOpen = false" />
            </div>
            <UVerticalNavigation :links="linksMobile" class="mt-9"/>
          </div>
        </USlideover>
      </Teleport>
    </ClientOnly>
  </div>
</template>
<style lang="scss" scoped>
.logo {
  width: 140px;
}
</style>
